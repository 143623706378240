
import {computed, defineComponent, ref} from "vue";
import { useStore } from "vuex";
import moment from "moment/moment";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "kt-widget-leads-table-partner",
  components: {
  //
  },
  props: {
    widgetClasses: String,
    hideBtnNewMember: Boolean
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const checked = ref(false);

    const leadsList = computed(() => {
      return store.getters.getterLeadsList
    })

    const dateFormat = (date: string) => {
      return moment(date).format('MMM DD, YYYY');
    }

    return {
      leadsList,
      checked,
      dateFormat,
      t,
    };
  },
});
