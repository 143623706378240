<template>
  <WidgetLeadsTablePartner />
</template>

<script>
import { setCurrentPageBreadcrumbs } from "../../../core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import WidgetLeadsTablePartner from "@/components/widgets/tables/WidgetLeadsTablePartner";

export default {
  name: "Leads",
  components: {
    WidgetLeadsTablePartner
  },
  computed: {
    /*currentRouteName() {
      return this.$route.name;
    }*/
  },
  created() {
    store.dispatch(Actions.GET_LEADS_LIST_BY_PARTNER)
  },
  setup() {
    setCurrentPageBreadcrumbs("Lead", []);
  }
}
</script>

<style scoped>

</style>